import { Menubar } from "primereact/menubar";
import logo from "../assets/images/CAC-logo.png";
import { Button } from "primereact/button";
import { useNavigate, Link } from "react-router-dom";

const CustomMenu = () => {
  let navigate = useNavigate();

  // Helper function for creating internal menu items
  const createInternalLinkItem = (label, path) => ({
    template: (
      <span
        className="inline-flex gap-1 px-2 py-2"
        style={{
          display: "flex", // Ensure flex container
          flexDirection: "column", // Stack text vertically
          alignItems: "flex-start", // Align text to the start (left)
          width: "100%", // Ensure full width for alignment
        }}
      >
        <Link
          to={path}
          style={{
            textDecoration: "none",
            color: "black",
            textAlign: "left", // Explicitly set text alignment
            display: "block", // Ensure Link behaves like a block element
          }}
        >
          {label}
        </Link>
      </span>
    ),
    command: () => navigate(path),
  });

  const createExternalLinkItem = (label, url) => ({
    template: (
      <span
        className="inline-flex gap-1 px-2 py-2"
        style={{
          display: "flex", // Ensure flex container
          flexDirection: "column", // Stack text vertically
          alignItems: "flex-start", // Align text to the start (left)
          width: "100%", // Ensure full width for alignment
        }}
      >
        <a
          href={url}
          className="menu-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "None", color: "black" }}
        >
          {label}
        </a>
      </span>
    ),
    label: (
      <span
        className="inline-flex gap-1 px-2 py-2"
        style={{
          display: "flex", // Ensure flex container
          flexDirection: "column", // Stack text vertically
          alignItems: "flex-start", // Align text to the start (left)
          width: "100%", // Ensure full width for alignment
        }}
      >
        <a
          href={url}
          className="menu-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "None", color: "black" }}
        >
          {label}
        </a>
      </span>
    ),
    url: { url },
  });

  const items = [
    {
      label: "The diplomatic and consular networks database and rankings",
      items: [
        createExternalLinkItem("Dashboard", "https://arcg.is/01rna0"),
        createInternalLinkItem("City ranking", "/city-ranking"),
        createInternalLinkItem("Country rankings", "/country-rankings"),
        createExternalLinkItem(
          "Methodology",
          "https://zenodo.org/records/14034985"
        ),
        createInternalLinkItem("Update", "/update"),
      ],
    },
    {
      label: "Publications",
      items: [
        createInternalLinkItem("2025 ranking report", "/2025-ranking-report"),
        createInternalLinkItem("Authors", "/authors"),
        createInternalLinkItem("Reports", "/reports"),
        createExternalLinkItem(
          "Submissions",
          "https://zenodo.org/communities/consularaffairs/curation-policy"
        ),
      ],
    },
    {
      label: "Honorary consuls",
      items: [
        createInternalLinkItem(
          "Who are honorary consuls?",
          "/who-are-honorary-consuls"
        ),
        createInternalLinkItem(
          "Honorary bibliography",
          "/honorary-bibliography"
        ),
        createInternalLinkItem(
          "Honorary corps directory",
          "/honorary-corps-directory"
        ),
        createInternalLinkItem("Honorary jobs", "/honorary-jobs"),
        createInternalLinkItem("Honorary news", "/honorary-news"),
        createInternalLinkItem("Henry Graham Greene", "/henry-graham-greene"),
      ],
    },
    {
      label: "Resources",
      items: [
        createInternalLinkItem(
          "What are consular affairs?",
          "/what-are-consular-affairs"
        ),
        createInternalLinkItem(
          "What are diplomatic networks?",
          "/what-are-diplomatic-networks"
        ),
        createInternalLinkItem(
          "1961 and 1963 Vienna conventions",
          "/1961-and-1963-vienna-conventions"
        ),
        createInternalLinkItem("Bibliography", "/bibliography"),
        createInternalLinkItem("Country profiles", "/country-profiles"),
        createInternalLinkItem("Daily press review", "/daily-press-review"),
        createInternalLinkItem("Did you know?", "/did-you-know"),
        createInternalLinkItem("Diplomatic directory", "/diplomatic-directory"),
        createInternalLinkItem(
          "Global Consular Forum",
          "/global-consular-forum"
        ),
        createInternalLinkItem("Glossary", "/glossary"),
        createInternalLinkItem(
          "Laws, regulations, and policies",
          "/laws-regulations-and-policies"
        ),
        createInternalLinkItem("Interesting links", "/interesting-links"),
        createInternalLinkItem("Weekly newsletter", "/weekly-newsletter"),
      ],
    },
    {
      label: "The Center",
      items: [
        createInternalLinkItem("About", "/about"),
        createInternalLinkItem("Research fellows", "/research-fellows"),
        createInternalLinkItem("Contributors", "/contributors"),
        createInternalLinkItem("Correspondents", "/correspondents"),
        createInternalLinkItem("Partners", "/partners"),
        createInternalLinkItem("Donate", "/donate"),
        createInternalLinkItem("Notice", "/notice"),
        createInternalLinkItem("Contact", "/contact"),
      ],
    },
  ];

  const start = (
    <Link to="/">
      <img src={logo} alt="Image" className="cac-logo-size" />
    </Link>
  );

  const navigateToDonate = () => {
    navigate("/donate");
  };

  const end = (
    <Button
      label="Donate"
      className="button-donate"
      onClick={navigateToDonate}
    />
  );

  return (
    <header className="menu-bar">
      <div className="reduce-width">
        <Menubar model={items} start={start} end={end} />
      </div>
    </header>
  );
};

export default CustomMenu;
