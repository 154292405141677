import { Image } from "primereact/image";
import "primeicons/primeicons.css";
import logo from "../assets/images/CAC-logo.png";
import { Link } from "react-router-dom";
import { MySocialNetworks } from "./Common/MySocialNetworks";

function CustomFooter() {
  return (
    <div className="footer-bar">
      <div className="reduce-width">
        <div className="flex justify-content-between">
          <div className="align-items-center justify-content-center">
            <div>
              <h4 className="footer-bar-menu mb-3">
                The diplomatic and consular networks database
              </h4>
            </div>
            <div className="mb-2">
              <a href="https://arcg.is/01rna0" className="footer-bar-menu">
                Dashboard
              </a>
            </div>
            <div className="mb-2">
              <Link to="city-ranking" className="footer-bar-menu">
                City ranking
              </Link>
            </div>
            <div className="mb-2">
              <Link to="country-rankings" className="footer-bar-menu">
                Country rankings
              </Link>
            </div>
            <div className="mb-2">
              <a
                href="https://zenodo.org/records/14034985"
                className="footer-bar-menu"
              >
                Methodology
              </a>
            </div>
            <div className="mb-2">
              <Link to="update" className="footer-bar-menu">
                Update
              </Link>
            </div>
          </div>
          <div className="align-items-center justify-content-center">
            <div>
              <h4 className="footer-bar-menu mb-3">Publications</h4>
            </div>
            <div className="mb-2">
              <Link to="2025-ranking-report" className="footer-bar-menu">
                2025 Ranking Report
              </Link>
            </div>
            <div className="mb-2">
              <Link to="authors" className="footer-bar-menu">
                Authors
              </Link>
            </div>
            <div className="mb-2">
              <Link to="reports" className="footer-bar-menu">
                Reports
              </Link>
            </div>
            <div className="mb-2">
              <a
                href="https://zenodo.org/communities/consularaffairs/curation-policy"
                className="footer-bar-menu"
              >
                Submissions
              </a>
            </div>
          </div>
          <div className="align-items-center justify-content-center">
            <div>
              <h4 className="footer-bar-menu mb-3">Honorary consuls</h4>
            </div>
            <div className="mb-2">
              <Link to="who-are-honorary-consuls" className="footer-bar-menu">
                Who are honorary consuls?
              </Link>
            </div>
            <div className="mb-2">
              <Link to="honorary-bibliography" className="footer-bar-menu">
                Honorary bibliography
              </Link>
            </div>
            <div className="mb-2">
              <Link to="honorary-corps-directory" className="footer-bar-menu">
                Honorary corps directory
              </Link>
            </div>
            <div className="mb-2">
              <Link to="honorary-jobs" className="footer-bar-menu">
                Honorary jobs
              </Link>
            </div>
            <div className="mb-2">
              <Link to="honorary-news" className="footer-bar-menu">
                Honorary news
              </Link>
            </div>
            <div className="mb-2">
              <Link to="honorary-spaces" className="footer-bar-menu">
                Honorary spaces
              </Link>
            </div>
            <div className="mb-2">
              <Link to="henry-graham-greene" className="footer-bar-menu">
                Henry Graham Greene
              </Link>
            </div>
          </div>
          <div className="align-items-center justify-content-center">
            <div>
              <h4 className="footer-bar-menu mb-3">Resources</h4>
            </div>
            <div className="mb-2">
              <Link to="what-are-consular-affairs" className="footer-bar-menu">
                What are consular affairs?
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to="what-are-diplomatic-networks "
                className="footer-bar-menu"
              >
                What are diplomatic networks?
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to="1961-and-1963-vienna-conventions"
                className="footer-bar-menu"
              >
                1961 and 1963 Vienna conventions
              </Link>
            </div>
            <div className="mb-2">
              <Link to="bibliography" className="footer-bar-menu">
                Bibliography
              </Link>
            </div>
            <div className="mb-2">
              <Link to="country-profiles" className="footer-bar-menu">
                Country profiles
              </Link>
            </div>
            <div className="mb-2">
              <Link to="daily-press-review" className="footer-bar-menu">
                Daily press review
              </Link>
            </div>
            <div className="mb-2">
              <Link to="did-you-know" className="footer-bar-menu">
                Did you know?
              </Link>
            </div>
            <div className="mb-2">
              <Link to="diplomatic-directory" className="footer-bar-menu">
                Diplomatic directory
              </Link>
            </div>
            <div className="mb-2">
              <Link to="global-consular-forum" className="footer-bar-menu">
                Global Consular Forum
              </Link>
            </div>
            <div className="mb-2">
              <Link to="glossary" className="footer-bar-menu">
                Glossary
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to="laws-regulations-and-policies"
                className="footer-bar-menu"
              >
                Laws, regulations, and policies
              </Link>
            </div>
            <div className="mb-2">
              <Link to="interesting-links" className="footer-bar-menu">
                Interesting links
              </Link>
            </div>
            <div className="mb-2">
              <Link to="weekly-newsletter" className="footer-bar-menu">
                Weekly newsletter
              </Link>
            </div>
          </div>
          <div className="align-items-center justify-content-center">
            <div>
              <h4 className="footer-bar-menu mb-3">The Center</h4>
            </div>
            <div className="mb-2">
              <Link to="about" className="footer-bar-menu">
                About
              </Link>
            </div>
            <div className="mb-2">
              <Link to="research-fellows" className="footer-bar-menu">
                Research fellows
              </Link>
            </div>
            <div className="mb-2">
              <Link to="contributors" className="footer-bar-menu">
                Contributors
              </Link>
            </div>
            <div className="mb-2">
              <Link to="correspondents" className="footer-bar-menu">
                Correspondents
              </Link>
            </div>
            <div className="mb-2">
              <Link to="partners" className="footer-bar-menu">
                Partners
              </Link>
            </div>
            <div className="mb-2">
              <Link to="donate" className="footer-bar-menu">
                Donate
              </Link>
            </div>
            <div className="mb-2">
              <Link to="notice" className="footer-bar-menu">
                Notice
              </Link>
            </div>
            <div className="mb-2">
              <Link to="contact" className="footer-bar-menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-content-center item-center mt">
          <Link to="/" className="flex justify-content-center">
            <Image src={logo} alt="Image" width="40" />
          </Link>
        </div>
        <div className="flex justify-content-center">
          <img
            src={"images/Logo-US-Creative-Commons-BY-NC-4.0.svg"}
            alt=""
            width="80em"
          />
        </div>
        {MySocialNetworks()}
        <div className="flex justify-content-center">
          <ul className="footer-menu">
            <li>
              <Link to="about">About</Link>
            </li>
            <li>
              <Link to="privacy-and-terms">Privacy and terms</Link>
            </li>
            <li>
              <Link to="contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="flex justify-content-center" style={{ color: "black" }}>
          © 2025 Consular Affairs Center. With Yonsei University. All rights
          reserved.
        </div>
      </div>
    </div>
  );
}

export default CustomFooter;
