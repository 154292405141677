import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

class YearlyRankingReport extends Component {
  render() {
    return (
      <div>
        {AddPageHeader("2025 ranking report")}
        <div className="reduce-width">
          <Divider align="center">
            <h1>2025 ranking report</h1>
          </Divider>
          <div className="privacy-terms">
            <br />
            <p>
              The "2025 Diplomatic and Consular Networks Ranking Report" is set
              to be released.
            </p>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default YearlyRankingReport;
