import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const Contributors = () => {
  return (
    <div className="reduce-width">
      {AddPageHeader("Contributors")}
      <Divider align="center">
        <h1>Contributors</h1>
      </Divider>
    </div>
  );
};

export default Contributors;
